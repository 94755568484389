@use "../../theme/variables";

// Chips
.chip {
  align-items: center;
  background: variables.$bg-color-dark;
  border-radius: 5rem;
  display: inline-flex;
  font-size: 90%;
  height: variables.$unit-6;
  line-height: variables.$unit-4;
  margin: variables.$unit-h;
  max-width: variables.$control-width-sm;
  overflow: hidden;
  padding: variables.$unit-1 variables.$unit-2;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;

  &.active {
    background: variables.$primary-color;
    color: variables.$light-color;
  }

  .avatar {
    margin-left: -(variables.$unit-2);
    margin-right: variables.$unit-1;
  }

  .btn-clear {
    border-radius: 50%;
    transform: scale(.75);
  }
}
