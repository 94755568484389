body {
  // scrollbar-color: #000 #fff;
  // background-color: red;
  // background-color: $page-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  margin-top: 0;
  a, a:visited {
    color: $dark-color;
  }
}
a {
  color: $primary-color;
  text-decoration: underline;
  &:visited {
    color: $primary-color-light;
  }
}

h1,
.h1 {
  font-size: 2rem;
  color: #282828;
}
h2,
.h2 {
  font-size: 1.8rem;
}
h3,
.h3 {
  font-size: 1.4rem;
  margin-top: 1rem;
}
h4,
.h4 {
  font-size: 1.2rem;
  // border-bottom: 1px solid rgb(220, 220, 220);
  text-decoration: underline;
}
h5,
.h5 {
  font-size: 1rem;
}
h6,
.h6 {
  font-size: .8rem;
}

ul {
  list-style-type: "– ";
}

p + ul {
  margin-top: 0;
  margin-bottom: 1.2rem;
}

// a, a:visited {
//   color: ;
// }

::webkit-scrollbar {
  background: #fff;
}

.complete-color, .admin-color {
  // color: white;
  th, p, a, h1, h2, h3, h4, h5, h6 {
    color: white;
  }
  tr td {
    // color: white;
    background-color: white;
  }
}

a.complete-color, a.admin-color {
  color: white;
}

.complete-color {
  background-color: $primary-color;
}

.light-gray-color {
  background-color: $light-gray-color;
}

.admin-color {
  background-color: $contrast-color;
}

p .btn {
  margin: 0.1em 0;
}

// #body-wrapper .container {
//   padding: 0 0 2rem;
// }

// #start > #body-wrapper .section:first-of-type .column {
//   padding-top: 0;
//   padding-bottom: 2rem;
// }

// #start > #body-wrapper .section.modular-text .column, #start > #body-wrapper .section.modular-image-row .column {
//   padding-top: 0;
//   padding-bottom: 1rem;
// }

// .header-fixed #body-wrapper {
//   padding-top: 0;
// }

@media screen and (min-width: 981px){
  .grid-xl.container {
    & > div, & > section {
      // padding: 0 75px 0;
    }
    & > .portfolio-items {
      padding: 0;
    }
    & > .category-filters .column {
      padding-bottom: 1rem;
    }
  }
}

section.section {
  padding-left: env(safe-area-inset-left);
  padding-left: calc(0.4rem + env(safe-area-inset-left));
  padding-right: env(safe-area-inset-right);
  padding-right: calc(0.4rem + env(safe-area-inset-right))
}

.columns {
  margin-left: 0;
  margin-right: 0;
}

.column {
  // & > h1:first-child, & > h2:first-child, & > h3:first-child, & > h4:first-child, & > h5:first-child, & > h6:first-child {
  //   margin-top: 0;
  // }
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  // @media screen and (min-width: $size-md) {
  //   padding: 2rem;
  // }
  // @media screen and (min-width: $size-2x) {
  //   padding: 4rem 8rem 4rem 12rem;
  //   &:nth-child(even) {
  //     padding: 4rem 12rem 4rem 8rem;
  //   }
  //   .left & {
  //     // text-align: left;
  //     padding: 4rem 12rem 4rem 8rem;
  //     &:nth-child(even) {
  //       padding: 4rem 8rem 4rem 12rem;
  //     }
  //   }
  // }
}

.left .column {
  // padding:
}


body .dark-bg, body .darker-bg {
  background-color: $dark-color;
  // padding: 1.2rem 0;
  color: $gray-color;
  // h1, h2, h3, h4, h5, h6 {
  //   color: $gray-color;
  // }
}

body.dark-bg {
  background-color: $dark-color;
  color: #fff;
  // h1, h2, h3, h4, h5, h6 {
  //   color: #fff;
  // }
  h1 {
    color: $gray-color;
  }
  #footer-contact h1 {
    color: #fff;
  }
  .modal {
    color: #000;
  }
}

.header-dark #header:not(.scrolled) {
  // background-color: rgba(#222, 0.65)
}


body.header-fixed.header-animated #header.scrolled {
  height: auto;
  // box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.1);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

body.header-fixed.header-animated #header.scrolled .navbar-section {
  height: auto;
}

#header h1, .mobile-logo h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5rem;
    @media screen and (min-width: 840px) {
      font-size: 2.2rem;
      margin-bottom: 0.5rem;
    }
    text-align: left;
    transform: scale(0.95,1);
    transform-origin: 50% 50%;
}

// @mixin body-gradient($start-color, $default-background) {
//   background: $default-background;
//   background: -moz-linear-gradient(180deg, $start-color 0%, $start-color 100%, rgba(255,255,255,1) 100%);
//   background: -webkit-linear-gradient(180deg, $start-color 0%, $start-color 100%, rgba(255,255,255,1) 100%);
//   background: linear-gradient(180deg, $start-color 0%, $start-color 100%, rgba(255,255,255,1) 100%);
//   background-size: 100% 400px;
//   background-repeat: no-repeat;
// }

#start {
  // @include body-gradient(#f8f8f9, #fff);
  // background-color: $primary-color;
}

.mobile-logo {
  // padding: 1rem 1.4rem;
  visibility: hidden;
}

// a[name]:not([href]) {
//     padding-top: 50px;
//     margin-top: -50px;
// }
// a[name]:not([href]):before {
//     display: block;
//     content: " ";
//     padding-top: 50px;
//     margin-top: -50px;
//     visibility: hidden;
// }

[id]::before {
  content: '';
  display: block;
  height:      80px;
  margin-top: -80px;
  visibility: hidden;
  position: absolute;
  z-index: -1
}

h1, h2, h3, h4, h5, h6 {
  // text-transform: uppercase;
  // font-family: 'Nexa W05', Helvetica, 'Helvetica Neue', Arial, sans-serif;
  // font-family: 'Archivo Black', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  line-height: 1.1;
  font-weight: normal;
}

h1 {
  font-size: 1.8rem;
  margin: 2rem 0 0.5em;
  @media screen and (min-width: $size-md) {
    font-size: 2.8rem;
  }
  // color: $gray-color;
}

img.border {
  border: 1px solid silver;
}

figure {
  margin: 0;
  position: relative;
  line-height: 0;
  img {
    // width: 100%;
  }
  figcaption {
    // color: #fff;
    font-style: italic;
    font-size: 0.8rem;
    line-height: 1rem;
    // position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.4rem 0.6rem;
  }
}

#item ul li {
  list-style-type: none;
  &::before {
    font-size: 0.9rem;
    content: "\2022";
    display: inline-block;
    width: 1em;
    margin-left: -1em
  }
}


.item-meta {
  h1:first-child {
    margin: 0;
  }
}


.modular-main-text .column > figure, .modular-main-text .column > img {
  margin: 0 1rem 1.8rem;
}

.featherlight-content {
  .featherlight-close-icon {
    font-weight: 700;
  }
  .caption {
    padding: 1rem 0.5rem 0;
    font-style: italic;
  }
}
.featherlight-next span, .featherlight-previous span {
  text-shadow: none;
  font-family: sans-serif;
}

// input {
// 	outline: none;
// }
// input[type=search] {
// 	-webkit-appearance: textfield;
// 	-webkit-box-sizing: content-box;
// 	font-family: inherit;
// 	font-size: 100%;
// }
// input::-webkit-search-decoration,
// input::-webkit-search-cancel-button {
// 	display: none;
// }
//
// input[type=search] {
//   background-color: none;
//   background-image: url(../images/search.svg);
//   background-repeat: no-repeat;
//   background-position: 9px center;
//   background-size: 18px 18px;
//   border: solid 1px #fff;
//   padding: 2px 10px 2px 32px;
//   width: 55px;
//
//   -webkit-border-radius: 10em;
//   -moz-border-radius: 10em;
//   border-radius: 10em;
//
//   -webkit-transition: all .5s ease;
//   -moz-transition: all .5s ease;
//   transition: all .5s ease;
// }
// input[type=search]:focus {
//   width: 130px;
//   background-color: #fff;
//   border-color: #fff;
//
//   -webkit-box-shadow: 0 0 5px rgba($primary-color,.5);
//   -moz-box-shadow: 0 0 5px rgba($primary-color,.5);
//   box-shadow: 0 0 5px rgba($primary-color,.5);
// }


input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}
