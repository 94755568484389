@use "../../theme/variables";

// Tables
.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  @if variables.$rtl == true {
    text-align: right;
  } @else {
    text-align: left;
  }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background: variables.$bg-color;
      }
    }
  }

  &,
  &.table-striped {
    tbody {
      tr {
        &.active {
          background: variables.$bg-color-dark;
        }
      }
    }
  }

  &.table-hover {
    tbody {
      tr {
        &:hover {
          background: variables.$bg-color-dark;
        }
      }
    }
  }

  // Scollable tables
  &.table-scroll {
    display: block;
    overflow-x: auto;
    padding-bottom: .75rem;
    white-space: nowrap;
  }

  td,
  th {
    border-bottom: variables.$border-width solid variables.$border-color;
    padding: variables.$unit-3 variables.$unit-2;
  }
  th {
    border-bottom-width: variables.$border-width-lg;
  }
}
