@use 'sass:color';
@use 'sass:math';

// Spectre Overrides
$primary-color: #23602b;
$primary-color-dark: darken($primary-color, 10%);
$primary-color-variant: #7fb069;
$primary-color-variant-dark: darken($primary-color-variant, 10%);
$secondary-color: #e6aa68;
$secondary-color-dark: darken($secondary-color, 10%);
$secondary-color-variant: #fffbbd;
$secondary-color-variant-dark: darken($secondary-color-variant, 10%);
$contrast-color: #03435d;
$contrast-color-dark: darken($contrast-color, 10%);
$contrast-color-light: color.scale($contrast-color, $lightness: 15%);
$contrast-color-variant: lighten($contrast-color, 10%);

// $page-color: lighten($primary-color, 72%);
$page-color: color.scale($primary-color, $lightness: 95%);

$dark-color: #282828;
$darker-color: #111;
$light-color: #fff;
$gray-color: #a8a8a7;
$gray-color-dark: darken($gray-color, 25%);
$gray-color-light: #efeeee;
$border-color: lighten($dark-color, 60%);
$bg-color: lighten($dark-color, 66%);
$light-gray-color: #efeeee;
// $body-font-color: lighten($dark-color, 5%) !default;
$body-font-color: $gray-color-dark;

$pip-teal: #004871;
$pip-orange: #db7536;
$pip-blue: #005db3;
$pip-red: #bb2739;
$pip-dark-blue: #003458;
$pip-yellow: #f9dd5a;
$pip-green: #25503d;

// Layout
$horiz-padding: 1rem;
$vert-padding: 2rem 0 2rem;

// Fonts
$min-responsive-font-size: 18px;

// Header
$header-height-large: 4rem;
$header-height-small: 2.3rem;
$header-color-dark: $dark-color;
$header-text-light: $light-color;
$header-text-dark: darken($dark-color, 15%);

// Dropdown Menu
$dropmenu-bg: $light-color;
$dropmenu-hover-text: $primary-color;
$dropmenu-horiz-padding: 20px;
$dropmenu-vert-padding: 7px;
$dropmenu-child-padding: 10px;
$dropmenu-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

// Mobile Menu
$mobile-color-main: $dark-color;
$mobile-color-active: #000;
$mobile-color-link: #000;
$mobile-button-height: 1em;
$mobile-button-width: 4em;



$size-xl: 1800px;
$size-2x: 1600px;


// spectre Core variables
$version: "0.5.9";

// Core features
$rtl: false !default;

// Core colors
$primary-color-dark: darken($primary-color, 3%) !default;
$primary-color-light: lighten($primary-color, 3%) !default;
$secondary-color-dark: darken($secondary-color, 3%) !default;
$secondary-color-light: lighten($secondary-color, 3%) !default;

$border-color: lighten($dark-color, 65%) !default;
$border-color-dark: darken($border-color, 10%) !default;
$border-color-light: lighten($border-color, 8%) !default;
$bg-color: lighten($dark-color, 75%) !default;
$bg-color-dark: darken($bg-color, 3%) !default;
$bg-color-light: $light-color !default;

// Control colors
$success-color: #32b643 !default;
$warning-color: #ffb700 !default;
$error-color: #e85600 !default;

// Other colors
$code-color: #d73e48 !default;
$highlight-color: #ffe9b3 !default;
$body-bg: $bg-color-light !default;
$body-font-color: lighten($dark-color, 5%) !default;
$link-color: rgb(0, 101, 201) !default;
$link-color-dark: darken($link-color, 10%) !default;
$link-color-light: lighten($link-color, 10%) !default;

// Fonts
// Credit: https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/
$base-font-family: 'Open Sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
$header-font-family: 'Sanchez', 'Roboto Slab', 'Open Sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
$mono-font-family: "SF Mono", "Segoe UI Mono", "Roboto Mono", Menlo, Courier, monospace !default;
$fallback-font-family: "Helvetica Neue", sans-serif !default;
$cjk-zh-hans-font-family: $base-font-family, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", $fallback-font-family !default;
$cjk-zh-hant-font-family: $base-font-family, "PingFang TC", "Hiragino Sans CNS", "Microsoft JhengHei", $fallback-font-family !default;
$cjk-jp-font-family: $base-font-family, "Hiragino Sans", "Hiragino Kaku Gothic Pro", "Yu Gothic", YuGothic, Meiryo, $fallback-font-family !default;
$cjk-ko-font-family: $base-font-family, "Malgun Gothic", $fallback-font-family !default;
$body-font-family: $base-font-family, $fallback-font-family !default;

// Unit sizes
$unit-o: .05rem !default;
$unit-h: .1rem !default;
$unit-1: .2rem !default;
$unit-2: .4rem !default;
$unit-3: .6rem !default;
$unit-4: .8rem !default;
$unit-5: 1rem !default;
$unit-6: 1.2rem !default;
$unit-7: 1.4rem !default;
$unit-8: 1.6rem !default;
$unit-9: 1.8rem !default;
$unit-10: 2rem !default;
$unit-12: 2.4rem !default;
$unit-16: 3.2rem !default;

// Font sizes
$html-font-size: 20px !default;
$html-line-height: 1.5 !default;
$font-size: .8rem !default;
$font-size-sm: .7rem !default;
$font-size-lg: .9rem !default;
$line-height: 1.2rem !default;

// Sizes
$layout-spacing: $unit-2 !default;
$layout-spacing-sm: $unit-1 !default;
$layout-spacing-lg: $unit-4 !default;
$border-radius: $unit-h !default;
$border-width: $unit-o !default;
$border-width-lg: $unit-h !default;
$control-size: $unit-9 !default;
$control-size-sm: $unit-7 !default;
$control-size-lg: $unit-10 !default;
$control-padding-x: $unit-2 !default;
$control-padding-x-sm: $unit-2 * .75 !default;
$control-padding-x-lg: $unit-2 * 1.5 !default;
$control-padding-y: math.div(($control-size - $line-height) , 2) - $border-width !default;
$control-padding-y-sm: math.div(($control-size-sm - $line-height) , 2) - $border-width !default;
$control-padding-y-lg: math.div(($control-size-lg - $line-height) , 2) - $border-width !default;
$control-icon-size: .8rem !default;

$control-width-xs: 180px !default;
$control-width-sm: 320px !default;
$control-width-md: 640px !default;
$control-width-lg: 960px !default;
$control-width-xl: 1280px !default;

// Responsive breakpoints
$size-xs: 480px !default;
$size-sm: 600px !default;
$size-md: 840px !default;
$size-lg: 960px !default;
$size-xl: 1280px !default;
$size-2x: 1440px !default;

$responsive-breakpoint: $size-xs !default;

// Z-index
$zindex-0: 1 !default;
$zindex-1: 100 !default;
$zindex-2: 200 !default;
$zindex-3: 300 !default;
$zindex-4: 400 !default;
//end spectre

