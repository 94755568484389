.fotoboek-album, .fotoboek-listing {
    .gallery {
        list-style: none;
        padding: 0;
        margin: 0 0 1em;
        // display: flex;
        // flex-flow: row wrap;
        // display: flex;
        // flex-wrap: wrap;
        // align-content: flex-start;
        // justify-content: space-between;
        display: grid;
        grid-gap: 5px;
        li {
            width: 100%;
            padding: 0;
            margin: 0;
            aspect-ratio: 1 / 1;
            line-height: 0;
            // flex-grow: 1;
            // flex-basis: 300px;
            // max-width: 300px;
            // margin: 5px;
        }
        a {
            position: relative;
        }
        h4 {
            position: absolute;
            bottom: 0;
            margin: 0;
            /* top: auto; */
            /* margin-top: -100%; */
            color: #fff;
            padding: 0 0.2em 0.5em;
            width: 100%;
            font-size: 1.3em;
            text-shadow: 0px 0px 2px rgba(0,0,0,0.7);
        }
        a, img {
            height: 100%;
            width: 100%;}
    }
    .image-surround {
        line-height: 0;
    }

    @media (max-width: 575.98px) {
        .gallery {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (max-width: 768.98px) and (min-width: 576px) {
        .gallery {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (max-width: 991.98px) and (min-width: 768px) {
        .gallery {
            grid-template-columns: repeat(4, 1fr); 
        }
    }
    @media (max-width: 1199.98px) and (min-width: 992px) {
        .gallery {
            grid-template-columns: repeat(5, 1fr); 
        }
    }
    @media (min-width: 1200px) {
        .gallery {
            grid-template-columns: repeat(6, 1fr); 
        }
    }
}


@media (max-width: 575.98px) {
    .fotoboek-listing .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}