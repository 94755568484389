@use "../../theme/variables";
@use "mixins/toast";

// Toasts
.toast {
  @include toast.toast-variant(variables.$dark-color);
  border: variables.$border-width solid variables.$dark-color;
  border-radius: variables.$border-radius;
  color: variables.$light-color;
  display: block;
  padding: variables.$layout-spacing;
  width: 100%;

  &.toast-primary {
    @include toast.toast-variant(variables.$primary-color);
  }

  &.toast-success {
    @include toast.toast-variant(variables.$success-color);
  }

  &.toast-warning {
    @include toast.toast-variant(variables.$warning-color);
  }

  &.toast-error {
    @include toast.toast-variant(variables.$error-color);
  }

  a {
    color: variables.$light-color;
    text-decoration: underline;
    
    &:focus,
    &:hover,
    &:active,
    &.active {
      opacity: .75;
    }
  }

  .btn-clear {
    margin: variables.$unit-h;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
