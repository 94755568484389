@use "../../theme/variables";

@use "sass:color";

// Navs
.nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: variables.$unit-1 0;

  .nav-item {
    a {
      color: variables.$gray-color-dark;
      padding: variables.$unit-1 variables.$unit-2;
      text-decoration: none;
      &:focus,
      &:hover {
        color: variables.$primary-color;
      }
    }
    &.active {
      & > a {
        color: color.adjust(variables.$gray-color-dark, $lightness: -10%, $space: hsl);
        font-weight: bold;
        &:focus,
        &:hover {
          color: variables.$primary-color;
        }
      }
    }
  }

  & .nav {
    margin-bottom: variables.$unit-2;
    margin-left: variables.$unit-4;
  }
}
