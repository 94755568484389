@use "../../theme/variables";

// Accordions
.accordion {
  input:checked ~,
  &[open] {
    & .accordion-header > {
      .icon:first-child {
        transform: rotate(90deg);
      }
    }

    & .accordion-body {
      max-height: 50rem;
    }
  }

  .accordion-header {
    display: block;
    padding: variables.$unit-1 variables.$unit-2;

    .icon {
      transition: transform .25s;
    }
  }

  .accordion-body {
    margin-bottom: variables.$layout-spacing;
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s;
  }
}

// Remove default details marker in Webkit
summary.accordion-header {
  &::-webkit-details-marker {
    display: none;
  }
}
