@use "../../theme/variables";

// Tabs
.tab {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: variables.$unit-1 0 (variables.$unit-1 - variables.$border-width) 0;

  .tab-item {
    border-bottom: variables.$border-width-lg solid transparent;
    color: inherit;
    cursor: pointer;
    display: block;
    margin: 0 variables.$unit-2 0 0;
    padding: variables.$unit-2 variables.$unit-1 variables.$unit-2 - variables.$border-width-lg variables.$unit-1;
    text-decoration: none;

    &:focus,
    &:hover {
      color: variables.$link-color;
    }

    &.tab-action {
      flex: 1 0 auto;
      text-align: right;
    }

    .btn-clear {
      margin-top: -(variables.$unit-1);
    }
  }

  &.tab-block {
    .tab-item {
      flex: 1 0 0;
      text-align: center;
      margin: 0;

      .badge {
        &[data-badge]::after {
          position: absolute;
          right: variables.$unit-h;
          top: variables.$unit-h;
          transform: translate(0, 0);
        }
      }
    }
  }

  &:not(.tab-block) {
    .badge {
      padding-right: 0;
    }
  }

  > input[type="radio"] {
    position: absolute;
    left: -100%;

    &:checked + .tab-item {
      border-bottom-color: variables.$primary-color;
      color: variables.$link-color;
    }

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}):checked ~ .tab-panel:nth-of-type(#{$i}) {
        display: block;
      }
    }
  }

  .tab-panel {
    border-top: variables.$border-width solid variables.$border-color;
    display: none;
    flex-basis: 100%;
  }
}
