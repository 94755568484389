@use "../../theme/variables";
@use "mixins/shadow";

// Menus
.menu {
  @include shadow.shadow-variant(.05rem);
  background: variables.$bg-color-light;
  border-radius: variables.$border-radius;
  list-style: none;
  margin: 0;
  min-width: variables.$control-width-xs;
  padding: variables.$unit-2;
  transform: translateY(variables.$layout-spacing-sm);
  z-index: variables.$zindex-3;

  &.menu-nav {
    background: transparent;
    box-shadow: none;
  }

  .menu-item {
    margin-top: 0;
    padding: 0 variables.$unit-2;
    position: relative;
    text-decoration: none;

    & > a {
      border-radius: variables.$border-radius;
      color: inherit;
      display: block;
      margin: 0 (-(variables.$unit-2));
      padding: variables.$unit-1 variables.$unit-2;
      text-decoration: none;
      &:focus,
      &:hover {
        background: variables.$secondary-color;
        color: variables.$primary-color;
      }
      &:active,
      &.active {
        background: variables.$secondary-color;
        color: variables.$primary-color;
      }
    }

    .form-checkbox,
    .form-radio,
    .form-switch {
      margin: variables.$unit-h 0;
    }

    & + .menu-item {
      margin-top: variables.$unit-1;
    }
  }

  .menu-badge {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    .label {
      margin-right: variables.$unit-2;
    }
  }
}