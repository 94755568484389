@use "../../theme/variables";

// Bars
.bar {
  background: variables.$bg-color-dark;
  border-radius: variables.$border-radius;
  display: flex;
  flex-wrap: nowrap;
  height: variables.$unit-4;
  width: 100%;

  &.bar-sm {
    height: variables.$unit-1;
  }

  // TODO: attr() support
  .bar-item {
    background: variables.$primary-color;
    color: variables.$light-color;
    display: block;
    font-size: variables.$font-size-sm;
    flex-shrink: 0;
    line-height: variables.$unit-4;
    height: 100%;
    position: relative;
    text-align: center;
    width: 0;

    &:first-child {
      border-bottom-left-radius: variables.$border-radius;
      border-top-left-radius: variables.$border-radius;
    }
    &:last-child {
      border-bottom-right-radius: variables.$border-radius;
      border-top-right-radius: variables.$border-radius;
      flex-shrink: 1;
    }
  }
}

// Slider bar
.bar-slider {
  height: variables.$border-width-lg;
  margin: variables.$layout-spacing 0;
  position: relative;

  .bar-item {
    left: 0;
    padding: 0;
    position: absolute;
    &:not(:last-child):first-child {
      background: variables.$bg-color-dark;
      z-index: variables.$zindex-0;
    }
  }

  .bar-slider-btn {
    background: variables.$primary-color;
    border: 0;
    border-radius: 50%;
    height: variables.$unit-3;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    width: variables.$unit-3;

    &:active {
      box-shadow: 0 0 0 .1rem variables.$primary-color;
    }
  }
}
