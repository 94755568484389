@use "../../theme/variables";

// Empty states (or Blank slates)
.empty {
  background: variables.$bg-color;
  border-radius: variables.$border-radius;
  color: variables.$gray-color-dark;
  text-align: center;
  padding: variables.$unit-16 variables.$unit-8;

  .empty-icon {
    margin-bottom: variables.$layout-spacing-lg;
  }

  .empty-title,
  .empty-subtitle {
    margin: variables.$layout-spacing auto;
  }

  .empty-action {
    margin-top: variables.$layout-spacing-lg;
  }
}
