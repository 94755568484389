.form-oauth2 button.wordpress, .form-oauth2 button.scouting {
    background: $primary-color;
    color: #fff;
}
.form-oauth2 button.wordpress:hover, .form-oauth2 button.scouting:hover {
    background: $primary-color-dark;
}

#body-wrapper #grav-login {
    margin: 1rem auto;
    padding: 1rem 1rem 1rem 1rem;
    // border: none;
    // background-color: inherit;
}
