section.section.modular-features {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    .column > a {
        text-decoration: none;
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .feature-content {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
    }
}

.modular-features.small .columns {
    align-items: flex-start;
}

.modular-features .column {
    min-height: 250px;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    position: relative;

    h3,
    p,
    i {
        line-height: 1.5;
        // background-color: rgba(#000,0.5);
        color: #fff;
        display: inline;
        padding: 0.11em;
    }

    i.bi {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        height: 2em;
        width: 2em;
        font-size: 2em;
        align-items: center;
        margin-top: 0.5em;
        font-weight: bold;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &.primary-color a {
        background-color: rgba($primary-color, 0.7);

        &:hover {
            background-color: rgba($primary-color, 0.8);
        }
    }

    &.secondary-color a {
        background-color: rgba($secondary-color, 0.7);

        h3,
        p,
        i {
            color: #000;
        }

        &:hover {
            background-color: rgba($secondary-color, 0.8);
        }
    }

    &.contrast-color a {
        background-color: rgba($contrast-color, 0.7);

        &:hover {
            background-color: rgba($contrast-color, 0.8);
        }
    }

    a {
        display: flex;
        flex-flow: column nowrap;
        padding: 1rem;
        height: 100%;
        justify-content: center;
        align-items: center;
        justify-items: center;
        align-content: center;

        &:hover {
            text-decoration: none;
        }
    }
}