@use 'sass:color';

#header {
  background-color: #fff;
  // background: lighten($secondary-color, 52%);
  // background: color.scale($primary-color, $lightness: 70%);
  font-family: $header-font-family;
  // background-color: $primary-color;
  width: 100%;
  height: $header-height-large;
  border-bottom: 1px solid rgba($gray-color, 0.2);
  // border-bottom: none;
  @extend .default-animation;
  transition: position 0.5s ease, height 0.5s ease, color 0.5s ease;
  font-size: 1rem;
  font-weight: 300;
  color: $dark-color;

  &.scrolled {
    .desktop-menu {
      display: flex;
      flex: 0 1 auto;
    }

    &~.mobile-menu {
      display: flex;
    }

    .logo {
      transform: scale(1);
      transform-origin: 50% 50%;

      svg path {
        fill: #000;
      }
    }
  }

  a {
    color: $dark-color;

    &.btn {
      color: #fff;
      line-height: 1.7;
    }
  }

  .header-dark &:not(.scrolled) {
    color: $dark-color;

    // a {
    //   color: $dark-color !important;
    // }
    a.active {
      color: $secondary-color !important;
    }

    // .dropmenu ul ul a {
    //     color: $dark-color !important;
    // }
  }

  .header-dark.header-transparent &:not(.scrolled) {
    background: rgba(#000, 0.05);
  }

  .header-transparent &:not(.scrolled) {
    background: rgba(#fff, 0.05);
  }

  .navbar-section {
    height: $header-height-large;
    margin-right: 0;
    @extend .default-animation;
  }

  .navbar-section.desktop-menu {
    transition: opacity 0.2s;
    display: flex;
    flex: 0 1 auto;
    // justify-content: flex-end;
    // .navbar &:not(:first-child):last-child {
    //   justify-content: flex-end;
    // }
    @include breakpoint(lg) {
      display: flex;
      flex: 1 0 auto;
      .dropmenu.animated {
        display: none;
      }
    }

    .secondary-menu-item {
      display: none;
    }

    // @include breakpoint(md) {
    //   display: none;
    // }
  }

  .logo {
    width: auto;
    max-width: 100%;
    flex: 1 0 120px;

    svg,
    img {
      max-width: 100%;

      // height: auto;
      // height: 30px;
      @media screen and (min-width: $size-sm) {
        // height: 36px;
      }

      @media screen and (min-width: $size-md) {
        // height: 42px;
      }

      display: inherit;
      @extend .default-animation;
    }
  }

  // Fixed Header solution
  .header-fixed & {
    position: fixed;
    top: 0;
    z-index: 3;
  }
}

// #header .navbar .navbar-section.desktop-menu {
//   justify-content: flex-end;
// }

.menu {
.menu-item {
  &>a {
    &:focus,
    &:hover {
      background: $primary-color-variant;
    }
  }
}
}


.header-fixed .hero {
  background-position: 50% 50%;
}

.navbar {
  // position: relative;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-flow: row nowrap;
  gap: 1em;
}


#header .navbar .navbar-section.desktop-menu {
  justify-content: flex-end;
}

.navbar .navbar-section {
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  @media screen and (min-width: 840px + 1) {
    flex: 1 1 60%;
  }

  body.mobile-nav-open &.scrolled {
    background-color: $dark-color;

    .logo {
      transform: scale(1);
      flex: 0 1 auto;
      transform-origin: 50% 50%;
    }
  }

  body.mobile-nav-open & {
    overflow-y: scroll;
    overflow-y: overlay;

    .logo {
      transform: scale(1);
      transform-origin: 50% 50%;
    }
  }

  &,
  & a,
  & .logo,
  & h1,
  & .dropmenu li {
    transition: background-color 0.5s ease, transform 0.5s ease, color 0.5s ease, padding 0.5s ease;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  .dropmenu {
    width: 100%;
    height: 100%;

    &>ul {
      height: 100%;
      position: unset;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;

      &>li {
        margin-left: 0.5em;
      }
    }

    ul div.subitems {
      // display: none;
      opacity: 0;
      visibility: hidden;
      transition: transform 0.3s, opacity 0.5s;
      transform: scale(0);
      transform-origin: 0 0;
      // width: 100%;
      // left: 0;
      flex-flow: column nowrap;
      position: absolute;
      top: 100%;
      list-style: none;
      background: #fff;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

      ul {
        max-width: 1816px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 0.4rem 0;
        width: 100%;
        display: none;
        flex-flow: column nowrap;
        justify-content: center;
        position: relative;
        visibility: visible;
        margin: 0 auto;
        box-shadow: none;
        /* transition: transform 0.3s, opacity 0.5s; */
        opacity: 1;
        transform: none;
      }
    }

    ul li {
      height: 100%;
      position: unset;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      // margin-right: ($dropmenu-horiz-padding + $dropmenu-child-padding);
      // margin-right: 0.5em;
      &:hover>div.subitems {
        display: flex;
        opacity: 1;
        visibility: visible;
        transform: scale(1);

        ul {
          display: flex;
        }
      }

      a {
        transition: all .2s;
        text-decoration: none;
        padding: 0.5em;
        display: block;
        padding-top: 0.7em;
        border-bottom: 0.2em solid transparent;

        &:hover,
        &:focus,
        &.active {
          // color: #303742 !important;
          border-bottom: 0.2em solid $primary-color;
        }

        &:before {
          content: '';
        }
      }

      // i {
      //   margin-left: 0.5em;
      // }
    }

    li ul li {
      // padding: 0.5em;
      // padding: 0 1em;
      font-style: italic;
      // border-left: 1px solid #dedede;
      margin: 0 0 0.5em 0;

      &:first-of-type {
        border-left: none;
      }

      a {
        padding: 0 0.5em;
        // margin: 0 1em;
      }
    }
  }

  li i {
    color: #a6a6a6;
  }

  li:hover i {
    color: #000;
  }

  &.scrolled {

    .logo {
      @media screen and (min-width: 840px) {
        transform: scale(0.8);
        transform-origin: 50% 50%;
        .navbar .navbar-section {
          flex: 1 1 100%;
        }
        //test
      }

      h1 {
        font-size: 1rem;

        @media screen and (min-width: 840px) {
          font-size: 1.2rem;
        }

        margin-bottom: 0;
        transform: none;
        transform-origin: 50% 50%;
      }
    }

    .dropmenu {
      ul {
        li {
          transform: scale(0.8);
          transform-origin: left;

          a {
            padding: 0 0.5rem 0 0;
          }
        }
      }
    }
  }
}

#header.scrolled .navbar .navbar-section {
  @media screen and (min-width: 840px) {
    flex: 1 1 220px;
    &.desktop-menu {
      flex: 1 0 auto;
      // background-color: red;
    }
  }

  .navbar .navbar-section:not(:first-child):last-child {
    padding-left: 1rem;
  }
}

.navbar .navbar-brand {
  font-size: 1.8rem;
  line-height: 1em;
}

body.dark-bg #header:not(.scrolled) {
  background: $header-color-dark;
  color: $light-color;
  // a {
  //   color: $light-color !important;
  // }
}

// Animate Fixed Header
body.header-fixed.header-animated {
  #header.scrolled {
    height: $header-height-small;

    .navbar-section {
      height: $header-height-small;
    }

    .dropmenu ul li a {
      font-size: 0.8rem;
      padding: 0.5em 0.5em 0.3em;
    }

    .logo {

      svg,
      img {
        height: 32px;
      }
    }
  }
}

.login-status-wrapper {
  white-space: nowrap;
  margin-left: 0.5em;
  height: 100%;
  /* vertical-align: middle; */
  /* align-self: center; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

#header .login-status a > .username {
  @include breakpoint(xs) {
    display: none;
  }
}

#header .login-status .menu .menu-item.username {
  display: none;
  font-weight: bold;
  font-size: 80%;
  @include breakpoint(xs) {
    display: block;
  }
}

#header .login-status .menu .menu-item>a {
  color: $dark-color;
  transition: none;
  i {
    color: $dark-color;
  }
}

#header .login-status .menu .menu-item>a:hover {
  background-color: $contrast-color;
  color: white;
  i {
    color: white;
  }
}