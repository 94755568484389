@use "../../../theme/variables";

// Component focus shadow
@mixin control-shadow($color: variables.$primary-color) {
  box-shadow: 0 0 0 .1rem rgba($color, .2);
}

// Shadow mixin
@mixin shadow-variant($offset) {
  box-shadow: 0 $offset ($offset + .05rem) * 2 rgba(variables.$dark-color, .15);
}
