@use "../../../theme/variables";

// Label base style
@mixin label-base() {
  border-radius: variables.$border-radius;
  line-height: 1.25;
  padding: .1rem .2rem;
}

@mixin label-variant($color: variables.$light-color, $bg-color: variables.$primary-color) {
  background: $bg-color;
  color: $color;
}
