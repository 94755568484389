@use "sass:math";

.mobile-container {
  position: absolute;
  //width: 100%;
  //height: 100%;
  top: 40%;
  left: 0;
  margin:  0 auto;
  z-index: 2;
}

.mobile-logo {
  svg, img {
    height: 42px;
    margin-top: .7rem;
    margin-left: 1.4rem;

    path.letter {
      // transform: translate(-141.87, -69.88);
      display: none;
      visibility: hidden;
      &.letter-c, &.letter-f {
        display: block;
        visibility: visible;
      }
    }
    path.letter-f {
      transform: translate(-245px, -69.88px);
    }

  }
}

// Hamburger Menu
.mobile-menu {
  position: relative;
  display: flex;
  // flex: 1 0 20%;
  order: 2;
  flex-flow: column nowrap;
  justify-content: center;
  // display: none;
  // display: block;
  // top: 0;
  // right: 0;
  top: auto;
  right: auto;
  z-index: 3;
  align-items: flex-end;

  .header-fixed & {
    // position: fixed;
    position: relative;
  }

  @media (max-width: 839px) {
    display: flex;
  }
  #header.scrolled & {
    display: flex;
  }

  .button_container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    position: relative;
    width: 1.2em;
    padding-left: env(safe-area-inset-right);
    // padding-top: 6px;
    // margin-right: $horiz-padding;
    height: 100%;
    min-height: 1em;
    // width: $mobile-button-width;
    // width: 2rem;
    font-size: 1.5rem;
    // margin-top: 3px;
    top: auto;
    right: auto;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease, top 0.5s ease;

    $bar-offset: math.div($mobile-button-height, 3);

    &:hover {
      opacity: .7;
    }

    &.active {
      position: relative;

      .top {
        transform: translateY(0.5em) translateX(0) rotate(45deg);
        background: $dark-color;
      }
      .middle {
        opacity: 0;
        transform: translateX(50%);
        background: $dark-color;
      }

      .bottom {
        transform: translateY(-0.5em) translateX(0) rotate(-45deg);
        background: $dark-color;
      }
    }

    span {
      // background: $dark-color;
      // body.dark-bg & {
      //   background-color: $mobile-color-main;
      // }
      border: none;
      height: 2px;
      margin-top: -2px; //compensate for height
      // width: 100%;
      width: 100%;
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      transition: all .35s ease;
      cursor: pointer;

      &:nth-of-type(2) {
        top: auto;
      }

      &:nth-of-type(3) {
        top: auto;
      }
    }
    #header.scrolled & {
      display: flex;
      font-size: 1.2rem;
    }
    #header.scrolled & span {
      background-color: #000;
      width: 1.2em;
      height: 2px;
    }
  }
  .login-status-wrapper {
    height: auto;
  }
}

.overlay {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;

  &.open {
    opacity: .95;
    visibility: visible;
    height: 100%;
    padding-top: 70px;
  }
  nav {
    position: relative;
    margin: 0 auto;
    padding-top: 0;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  .login-status {
    text-align: left;
    margin: 1rem 1rem 1rem 3rem;
    @media screen and (min-width: $size-md) {
      margin: 1rem 1rem 1rem 4rem;
      // padding: 1rem 8rem 1rem 9rem;
    }
    @media screen and (min-width: 1440px) {
      padding: 1rem;
    }
  }
}

.overlay-menu {
  height: calc(100%);
  overflow-y: scroll;
  overflow-y: overlay;

  & > .tree {
    text-align: left;
  }
}


.treemenu {

  &.treemenu-root {
    margin: 1rem;
    @media screen and (min-width: 1440px) {
      padding: 1rem;
    }
  }

  li {
    background: none;
    list-style: none;
    margin: 0 0 1px;
    padding: 5px 0;
    line-height: 1.2rem;
    background: transparent;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    a {
      text-decoration: none;
      font-family: $header-font-family;
      // text-transform: lowercase;
      font-weight: lighter;
      display: block;
      margin-left: 1.2rem;
      font-size: 1rem;
      border-left: 0.2em solid transparent;
      padding-left: 0.5em;
      flex: 1 1 calc(100% - 1.2rem);
      // color: $gray-color-light;
      @media screen and (min-width: $size-md) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-left: 1rem;
        flex: 1 1 calc(100% - 2.8rem);
      }
      &:hover, &:focus, &.active {
        // color: $light-color !important;
        text-decoration: none;
        border-left: 0.2em solid $primary-color;
        &:not([href]) {
          color: $gray-color-dark;
        }
      }
      // For togglers without link
      &:not([href]) {
        color: $gray-color-dark;
        &:hover, &:focus, &.active {
          color: $gray-color-dark;
          border-left: 0.2em solid transparent;
        }
      }
    }
    &.secondary-menu-item > a:not([href]), &.secondary-menu-item > a:hover:not([href]), &.secondary-menu-item > a:not([href]):hover {
      color: $gray-color-dark;
    }

    li a {
      margin-left: 0;
    }
    i {
      display: none;
    }
    ul {
      padding-left: 1rem;
    }
  }

  ul {
    margin: 0 0 0 1.7rem;
  }

  .toggler {
    // vertical-align: text-top;
    float: none;
    // line-height: 1;
    width: 1rem;
    font-size: 1rem;
    // @media screen and (min-width: $size-md) {
    //   font-size: 3rem;
    //   line-height: 1rem;
    // }
    // cursor: pointer;
    // vertical-align: top;
    // font-size: 2rem;
    // line-height: 2.5rem;
    padding-left: 0;
    // float: left;
    @media screen and (min-width: $size-md) {
      font-size: 1.5rem;
    }
    &:before {
      // // font-size: 1rem;
      // vertical-align: text-top;
    }


  // @include breakpoint(md) {
  //   font-size: 2rem;
  // }
    //   display: inline-block; margin-right: 2pt;
    // }
  }

  li.tree-empty  > .toggler {
    display: none;
    // opacity: 0.3; cursor: default;

    // &:before {
    //   content: "\2022";
    // }
  }

  li.tree-closed > .toggler:before {
    // content: "+";
  }

  li.tree-opened > .toggler:before {
    // content: "\2212";
  }
}



.treemenu li.tree-closed a, .treemenu li.tree-opened a {
  margin-left: .2rem;

  @media screen and (min-width: $size-md) {

    margin-left: 0;
  }
}

.mobile-nav-open {
  overflow-y: hidden;
  #header {background-color: transparent;}
  #header .navbar-section.desktop-menu {
    opacity: 0;
    @include breakpoint(md) {
      display: flex;
      & > .dropmenu.animated {
        display: none;
      }
    }
  }
  #header .navbar-section.logo {
    a {
      color: #fff;
      svg path {
        fill: #fff;
      }
    }
  }
}
