.modular-text {
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .content {
        padding: 1em 2em;
        @media screen and (min-width: $size-md) {
            padding: 2em 4em;
        }
    }
    body.dark-bg & .column {
        color: $gray-color;
        background-color: $gray-color-light;

        // padding: 4rem 1rem;
        &.image-comparison {
            .resize img {}

            padding: 0;
        }
    }

    // background-color: $gray-color;
    body &.dark-bg .column {
        background-color: $dark-color;
    }

    body &.light-bg .column {
        background-color: #fff;
    }

    &.bold {
        font-weight: 900;
    }

    &.large {
        font-size: 1.1em;

        @media screen and (min-width: $size-md) {
            font-size: 1.3rem;
        }
    }

    &.less-padding .column {
        @media screen and (min-width: $size-md) {
            padding: 1rem;
        }

        // @media screen and (min-width: $size-2x) {
        //   padding: 1rem 8rem 1rem 12rem;
        //   &:nth-child(even) {
        //     padding: 1rem 12rem 1rem 8rem;
        //   }
        //   .left & {
        //     // text-align: left;
        //     padding: 1rem 12rem 1rem 8rem;
        //     &:nth-child(even) {
        //       padding: 1rem 8rem 1rem 12rem;
        //     }
        //   }
        // }
    }

    &.bg-contain .column {
        background-size: contain;
    }

    &.large-title h3 {
        font-size: 2rem;

        @media screen and (min-width: $size-sm) {
            font-size: 3rem;
        }

        @media screen and (min-width: $size-md) {
            font-size: 4rem;
        }

        @media screen and (min-width: $size-2x) {
            font-size: 5rem;
        }
    }

    &.content-dark-bg .content {
        background-color: $dark-color;
        color: #fff;

        h3 {
            color: #fff;
        }
    }

    &.background .columns {
        min-height: 300px;

        @media screen and (min-width: $size-md) {
            min-height: 800px;
        }

        background-size: cover;
        text-align: center;
        background-position: center center;
        background-repeat: no-repeat;

        .column {
            text-align: center;
        }

        blockquote {
            padding: 0;
            margin: 0;
            border: none;
            color: #fff;

            p {
                line-height: 1.1;
                font-size: 3rem;

                @media screen and (min-width: $size-md) {
                    font-size: 4rem;
                }

                @media screen and (min-width: $size-2x) {
                    font-size: 5rem;
                }

                font-weight: 700;
            }

            cite {
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
            }
        }

        h3 {
            color: #fff;
            // font-size: 2rem;
            font-weight: 900;

            @media screen and (min-width: $size-md) {
                text-align: center;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        // color: $darker-color;
    }

    h1 {
        font-weight: 100;
        font-size: 3rem;
    }

    .column.image {
        text-align: center;
        // @media screen and (min-width: $size-md) {
        //   text-align: left;
        // }
        // padding: 1rem;
        min-height: 350px;

        img {
            object-fit: contain;
            max-height: 100%;
        }
    }

    // For vector icons/maps
    &.slide {

        .left .column.side-title,
        .column.side-title {
            padding: 0;

            a {
                height: 100%;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
            }

            h3 {
                padding: 1rem;
            }

            @media screen and (min-width: $size-2x) {
                h3 {
                    padding: 2rem 4rem 2rem 12rem;
                }
            }
        }

        .side-title img {
            max-height: 500px;
        }

        .image img {
            max-height: 100px;

            @media screen and (min-width: $size-md) {
                max-height: 200px
            }
        }
    }

    .column.side-content {
        padding: 0;
        min-height: 300px;
    }

    .column.side-title {
        background-size: cover;
        background-position: center;
        background-color: $pip-teal;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;

        @media screen and (min-width: $size-md) {
            padding-top: 4rem;
            padding-bottom: 4rem;
            align-items: flex-start;

            &.left {
                align-items: flex-end;
            }
        }

        a,
        a:visited {
            color: lighten($pip-teal, 10%);
        }

        @media screen and (min-width: $size-2x) {
            padding-left: 8rem;
        }

        h3 {
            font-weight: 900;
            color: #fff;
        }
    }

    .column>iframe {
        width: 100%;
        height: 100%;
    }

    .slide-down .left .column.side-title {
        padding-right: 0;
        padding-bottom: 0;
    }

    @media screen and (min-width: $size-2x) {
        .left .column.side-title {
            padding-left: 12rem;
            padding-right: 4rem;
        }
    }

    .image-comparison img {
        max-width: none;
    }
}

.modular-text.full-width {
    .column {
        padding: 0;
        min-height: 300px;

        @media screen and (min-width: $size-md) {
            min-height: 800px;
        }
    }
}

.toggle-slide img {
    max-height: 200px;
}

.section.modular-text .image {
    background-size: cover;
    background-position: 50% 50%;
}



.modular-text.contact-title {
    color: $gray-color;

    h1 {
        color: $gray-color;

        em {
            font-style: normal;
            color: #000;
        }
    }
}


body .darker-bg,
.dark-bg .modular-text.darker-bg {
    background-color: $darker-color;
    // h1, h2, h3, h4, h5, h6 {
    //   color: #fff;
    // }
}