#hero_carousel {
  // --f-carousel-slide-height: 60%;
  // --f-carousel-spacing: 10px;
  --f-button-color: #fff;
  --f-button-hover-color: #dedede;
  --f-transition-duration: 0.3s;
  --f-button-height: 52px;
  --f-button-width: 52px;
  --f-button-svg-height: 48px;
  --f-button-svg-width: 48px;
  .f-button {
    display: none;
  }
  @media screen and (min-width: 768px) {
    .f-button {
      display: flex;
    }
  }
}

#hero_carousel .f-carousel__viewport {
  position: absolute;
}

#hero_carousel .f-progress {
  bottom: 0;
  top: auto;
}

.hero {
  p {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    // background-color: rgba(0,0,0,0.4);
    background-color: rgba($contrast-color, 0.75);
    color: #fff;
    line-height: 1.5;
    display: inline;
    padding: 0.11em;
    margin-bottom: 0;
    white-space: normal;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    &:after {
      // display: block;
      // content: '\A\a';
      margin-bottom: 0.5em;
      background-color: transparent;
      content: "\A";
      white-space: pre; 
      line-height: 2;
      vertical-align: top;
    }
  }
  &.primary-header h1, &.primary-header h2 {
    background-color: rgba($primary-color, 0.75);
  }
  &.secondary-header h1, &.secondary-header h2 {
    background-color: rgba($secondary-color, 0.75);
  }
  &.contrast-header h1, &.contrast-header h2 {
    background-color: rgba($contrast-color, 0.75);
  }

  h1 {
    font-size: 1.4rem;
  }
  h2 {
    font-size: 0.9rem;
    &:after {
      line-height: 3;
    }
  }
  h3 {
    font-size: 1.6rem;
    // background-color: rgba($primary-color, 0.75);
  }

  h2, &.text-light h2 {
    color: #fff;
    // background-color: rgba($primary-color, 0.75);
  }
  @media screen and (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
      line-height: 1.5;
    }
    h2 {
      font-size: 1rem;
      line-height: 1.5;
    }
    h3 {
      font-size: 4rem;
      line-height: 1.5;
      // background-color: rgba($primary-color, 0.75);
    }
  }

  .btn {
    margin-bottom: 0.2em;
    margin-right: 0.1em;
    .bi {
      margin-right: 0;
    }
  }
}