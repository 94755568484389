#breadcrumbs a {
    text-decoration: none;
}

#breadcrumbs {
    display: block;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 1rem;
    span {
        padding: 0 0.5rem 0 0;
    }
    span>a>span,
    span>span>span {
        padding: 0;
    }

    span:not(:first-child)::before,
    a:not(:first-child)::before {
        color: $dark-color;
    }

    // padding-left: 0;
    // display: block;
    // align-items: center;
    // margin-top: -1rem;
    // margin-bottom: 1rem;
    // height: auto;
}