.modular-item-listing {
    h3 {
        margin-top: 2rem;
    }
    .card {
        // border: none;
    }
    .column {
        padding-left: 0;
        padding-right: 0;
    }
}