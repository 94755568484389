@use "../../../theme/variables";

// Loading
.loading {
  color: transparent !important;
  min-height: variables.$unit-4;
  pointer-events: none;
  position: relative;
  &::after {
    animation: loading 500ms infinite linear;
    background: transparent;
    border: variables.$border-width-lg solid variables.$primary-color;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: variables.$unit-4;
    left: 50%;
    margin-left: -(variables.$unit-2);
    margin-top: -(variables.$unit-2);
    opacity: 1;
    padding: 0;
    position: absolute;
    top: 50%;
    width: variables.$unit-4;
    z-index: variables.$zindex-0;
  }

  &.loading-lg {
    min-height: variables.$unit-10;
    &::after {
      height: variables.$unit-8;
      margin-left: -(variables.$unit-4);
      margin-top: -(variables.$unit-4);
      width: variables.$unit-8;
    }
  }
}
