@use "../../theme/variables";

// Panels
.panel {
  border: variables.$border-width solid variables.$border-color;
  border-radius: variables.$border-radius;
  display: flex;
  flex-direction: column;

  .panel-header,
  .panel-footer {
    flex: 0 0 auto;
    padding: variables.$layout-spacing-lg;
  }

  .panel-nav {
    flex: 0 0 auto;
  }

  .panel-body {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 0 variables.$layout-spacing-lg;
  }
}
