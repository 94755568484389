.btn, a.btn, button.btn {
    color: #fff;
    border-radius: 0.2em;
    // background-color: #fff;
    background-color: $secondary-color-dark;
    border-width: 0;
    // line-height: 1.4rem;
    svg.bi {
        height: 1.2em;
        vertical-align: middle;
    }
    // .bi {
    //     margin-right: 0.5em;
    // }
    &:hover {
        // background-color: $primary-color-variant;

        // &:visited {
        //     color: $primary-color;
        // }
    }
    &:visited {
        color: #fff;
    }
}
.btn.btn-lg {
    font-family: $header-font-family;
    // font-size: 1rem;
    // height: 2.2em;
    // padding: 0.5em 1em;
    @media screen and (min-width: 768px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
        height: 2.2em;
        padding: 0.5em 1em;
    }
}

.btn.primary-color {
    background-color: $primary-color;
    border-color: $primary-color-dark;
    &:hover {
        background-color: $primary-color-dark;
        border-color: $primary-color-dark;
    }
}

.btn.primary-color-variant {
    background-color: $primary-color-variant;
    border-color: $primary-color-variant-dark;
    &:hover {
        background-color: $primary-color-variant-dark;
        border-color: $primary-color-variant-dark;
    }
}

.btn.secondary-color {
    background-color: $secondary-color;
    border-color: $secondary-color-dark;
    &:hover {
        background-color: $secondary-color-dark;
        border-color: $secondary-color-dark;
    }
}

.btn.secondary-color-variant {
    background-color: $secondary-color-variant;
    border-color: $secondary-color-variant-dark;
    color: #000;
    &:hover {
        background-color: $secondary-color-variant-dark;
        border-color: $secondary-color-variant-dark;
        color: #000;
    }
}
.btn.contrast-color {
    background-color: $contrast-color;
    border-color: $contrast-color-dark;
    color: #fff;
    &:hover {
        background-color: $contrast-color-dark;
        border-color: $contrast-color-dark;
    }
}

// .btn.contrast-color {
//     background-color: $contrast-color;
//     color: #000;
// }