@use "../../theme/variables";
@use "mixins/label";

@use "sass:color";

// Codes
code {
  @include label.label-base();
  @include label.label-variant(variables.$code-color, color.adjust(variables.$code-color, $lightness: 42.5%, $space: hsl));
  font-size: 85%;
}

.code {
  border-radius: variables.$border-radius;
  color: variables.$body-font-color;
  position: relative;

  &::before {
    color: variables.$gray-color;
    content: attr(data-lang);
    font-size: variables.$font-size-sm;
    position: absolute;
    right: variables.$layout-spacing;
    top: variables.$unit-h;
  }

  code {
    background: variables.$bg-color;
    color: inherit;
    display: block;
    line-height: 1.5;
    overflow-x: auto;
    padding: 1rem;
    width: 100%;
  }
}
