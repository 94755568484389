// Sticky Footer solution
body.sticky-footer {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #page-wrapper {
    flex: 1 0 auto;
  }
}

#footer, #menu-footer {
  color: $gray-color;
  padding: 0 $horiz-padding 0;
  padding: 0 env(safe-area-inset-left) 0 env(safe-area-inset-right);
  text-align: center;
  margin-top: 1rem;
  a {
    color: $gray-color;
  }
  h5 {
    font-size: 1.3rem;
  }
  h6 {
    width: 100%;
    font-size: 1.2rem;
    margin-top: 0.5em;
    a {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      &:hover {
        font-weight: inherit;
        // text-decoration: inherit;
      }
    }
    // padding: 1rem 0;
  }
  .container {
    border-top: 1px solid #dedede;
  }
  // background-color: $light-color;
  // color: #000;
  // margin-top: 2rem;
  // padding-top: 0;
  // padding: inherit;
  text-align: left;
  a {
    // color: lighten(#acb3c2, 10%);
    font-weight: bold;
    &:active, &:hover, &:focus, &:visited {
      // color: lighten(#acb3c2, 15%);
    }
  }
  .footer-content {
    display: flex;
    align-items: flex-start;
    padding: 0;
  }

  p {
    margin: 10px;
    text-align: right;
    &:first-of-type {
      text-align: left;
    }
  }

  .logo {
    display: block;
    vertical-align: top;
    margin-right: 2rem;
   img, svg {
      // width: 42px;
      vertical-align: middle;
   }
  }
  .column {
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    @media screen and (min-width: $size-xl) {
      padding: 1rem;
    }
  }
  p {
    flex: 1 0 50%;
    margin: 0;
  }
}

#footer-contact {
  // background-color: $darker-color;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: bold;
  @media screen and (min-width: $size-2x) {
    .column {
      padding: 4rem 8rem 4rem 12rem;
    }
  }
  // color: #fff;
  h2 {
    font-size: 3rem;
    @media screen and (min-width: $size-lg) {
      font-size: 5rem;
    }
  }
  a {color: #fff;}
  #contact-form {
    display: none;
  }
  p {
    font-size: 1.5em;
    @media screen and (min-width: $size-md) {
      font-size: 1.4rem;
    }
  }
  p {
    margin: 0;
  }
  a[name]:not([href]):before {
    padding-top: 0;
    margin-top: 0;
  }
}

#menu-footer ul {
  list-style: none;
  margin: 0;
}
