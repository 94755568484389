.card {

  .card-header,
  .card-body {
    // padding-left: 0;
    // padding-right: 0;
  }
}

.content-item {

  nav.parent-page-link,
  h2 {
    font-size: 1.5rem;
    margin-top: 0;
    color: $dark-color;
    margin-bottom: .5rem;
    line-height: 1.1;

    a {
      text-decoration: none;
      color: $dark-color;
    }
  }

  h1 {
    margin-bottom: 0.5rem;
    color: $dark-color;
  }

  .content-image {
    margin-top: 0.5rem;
    min-height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}

#body-wrapper {
  iframe {
    width: 100%;
    min-height: 400px;
    /* display: block; */
    /* height: 300px; */
    display: block;
    margin-bottom: 1em;
  }
}

.blog-listing .card {
  justify-content: flex-start;
}


.card,
.blog-listing .card {
  border: 0.05rem solid #c1c1c1;
  margin-right: 10px;
  height: 100%;
  box-shadow: 0 10px 45px -9px rgba(0, 0, 0, 0.1);
  // background-color: inherit;
  // border: none;
  // box-shadow: none;
  margin-bottom: 0;
  .card-title {
    display: flex;
    flex-flow: row wrap;
  }
  .card-title > a:first-child {
    flex: 1 1 100%;
  }
  // width: 100%;
  .card-title h5 {
    margin: 0;
  }

  .card-title h6 {
    margin-top: .4rem;
  }

  .card-title .bi.bi-ui-checks {
    margin-left: 0.5em;
  }

  .card-text {
    width: 100%;
    flex: 1 1 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  .card-image:first-child img {
    max-height: 250px;
    object-fit: cover;
    width: 100%;
  }
  .card-actions {
    padding: 0.8rem;
  }

  @media screen and (min-width: 600px) {
    margin-bottom: 1rem;
    // width: 50%;

    .card-image:first-child img {
      max-height: 100%;
      object-fit: cover;
      width: 100%;
    }

    // object-fit: cover;
    // width: 100%;
  }

  @media screen and (min-width: 840px) {
    // width: 25%;
  }
}

.activiteit.old .card {
  background-color: #ececec;

  img {
    opacity: 0.7;
  }
}

.blog-listing .blog-date {
  font-size: 16px;
  font-weight: normal;
}