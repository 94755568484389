@use "../../theme/variables";

// Breadcrumbs
.breadcrumb {
  list-style: none;
  margin: variables.$unit-1 0;
  padding: variables.$unit-1 0;

  .breadcrumb-item {
    color: variables.$gray-color-dark;
    display: inline-block;
    margin: 0;
    padding: variables.$unit-1 0;

    &:not(:last-child) {
      margin-right: variables.$unit-1;

      a {
        color: variables.$gray-color-dark;
      }
    }

    &:not(:first-child) {
      &::before {
        color: variables.$gray-color-dark;
        content: "/";
        padding-right: variables.$unit-2;
      }
    }
  }
}
