.flex-table-wrapper {
    overflow-x: auto;
}
.flex-table {
    margin: 1em auto;
}

.flex-table tr:nth-child(2n) {
    background-color: rgba(240,240,240);
}

.flex-table tr.total {
    background-color: rgb(223, 223, 223);
    td {
        border-top: 1px solid black;
        font-weight: bold;
    }
}