.user-info {
    display: flex;
    align-items: stretch;
    .user {
        background-color: rgb(97, 97, 97);
        color: #fff;
        padding: 1rem;
        h3 {
            color: #fff;
        }
        a {
            color: #fff;
            margin-top: 1rem;
            display: block;
        }
        &.welpen {
            background-color: $primary-color;
        }
        &.verkenners {
            background-color: $contrast-color;
        }
        &.loodsen {
            background-color: $secondary-color;
        }
        &.bestuur {
            background-color: $secondary-color-dark;
        }
    }

    .roles {
        padding: 1rem;
        background-color: rgb(244,244,244);
        h4 {
            margin-top: 1rem;
            text-decoration: none;
        }
    }

    .links {
        padding: 1rem;
        background-color: $contrast-color;
        color: #fff;
        h4 {
            margin-top: 1rem;
            text-decoration: none;
            color: #fff;
        }
        a {
            color: #fff;
        }
    }
}